var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vessel-enquiry-open-data-table"},[_c('base-data-table',{attrs:{"title":((_vm.isCompaniesTable ? 'Companies' : 'Vessels') + " Enquiries"),"headers":_vm.headers,"items":_vm.vessels,"loading":_vm.loading,"item-class":_vm.validityStyle,"noCreate":_vm.agencyAdminRole},on:{"create-item":function($event){return _vm.$router.push({ name: ((_vm.isCompaniesTable ? 'Company' : 'Vessel') + " Enquiry Create") })}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.onEditVesselEnquiry(item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),(!_vm.agencyAdminRole)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('delete-vessel-enquiry', item)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1):_vm._e()],1)],1)]}},{key:"item.vesselName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.vesselName)+" "+_vm._s(item.vesselRegistrationNumber)+" ")]}},{key:"item.monthlyFeeGbp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyGbp")(item.monthlyFeeGbp))+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startDate))+" ")]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.endDate))+" ")]}},{key:"item.documentsSent",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"success","disabled":item.documentsReceived || _vm.agencyAdminRole},on:{"click":function($event){return _vm.onUpdateStatus(item)}},model:{value:(item.documentsSent),callback:function ($$v) {_vm.$set(item, "documentsSent", $$v)},expression:"item.documentsSent"}})]}},{key:"item.documentsReceived",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"success","disabled":!item.documentsSent || item.documentsUploaded || _vm.agencyAdminRole},on:{"click":function($event){return _vm.onUpdateStatus(item)}},model:{value:(item.documentsReceived),callback:function ($$v) {_vm.$set(item, "documentsReceived", $$v)},expression:"item.documentsReceived"}})]}},{key:"item.documentsUploaded",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"success","disabled":!item.documentsReceived || item.crewMemberReadyToGo || _vm.agencyAdminRole},on:{"click":function($event){return _vm.onUpdateStatus(item)}},model:{value:(item.documentsUploaded),callback:function ($$v) {_vm.$set(item, "documentsUploaded", $$v)},expression:"item.documentsUploaded"}})]}},{key:"item.crewMemberReadyToGo",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"color":"success","disabled":!item.documentsUploaded || _vm.agencyAdminRole},on:{"click":function($event){return _vm.onUpdateStatus(item)}},model:{value:(item.crewMemberReadyToGo),callback:function ($$v) {_vm.$set(item, "crewMemberReadyToGo", $$v)},expression:"item.crewMemberReadyToGo"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }